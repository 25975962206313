import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import policingEquityImg from "../../../images/PolicingEquity.png"
import brcImg from "../../../images/brc.svg"
import hopeForPawsImg from "../../../images/HopeForPaws.png"
import noKidHungryImg from "../../../images/NoKidHungry.png"
import questionMarkImg from "../../../images/QuestionMark.gif"

type DonationsProps = {
  contract: Contract,
  isTestNet: boolean,
}

const DISCORD_COMMUNITY_CHANNEL_INVITE_LINK = "https://discord.com/invite/YzhhEBGFhx"

const Donations: React.FC<DonationsProps> = ({ contract, isTestNet }) => {
  const [currentlyDonated, setCurrentlyDonated] = useState<number>(0)
  const [donationsWalletAddress, setDonationsWalletAddress] = useState<string>("")
  useEffect(() => {
    if (contract) {
      contract.methods.donationSum().call().then((donatedAmount: number) => {
        setCurrentlyDonated(donatedAmount/1000000000000000000)
      })
      contract.methods.donationsAddress().call().then((walletAddress: string) => {
        setDonationsWalletAddress(walletAddress)
      })
    }
  }, [contract])
  const etherScanBaseUrl = isTestNet ? "https://rinkeby.etherscan.io/address/" : "https://etherscan.io/address/"
  const donationsWalletEtherScanUrl = etherScanBaseUrl + donationsWalletAddress

  return (
    <>
      <Container>
        <div className="centered">
          <h1>Donations</h1>
          {contract && <h3>We have currently raised {currentlyDonated}Ξ</h3> }
          <p> CryptoCandies donates 20% of all our profits (primary and secondary) to various non-profits like the ones below via <a className="primary-color" href="https://www.thegivingblock.com/" target="_blank">The Giving Block</a>. When you purchase on the site the donation portion of that purchase will be routed directly to our <a className="primary-color" href={donationsWalletEtherScanUrl} target="_blank">donations wallet</a>. Secondary purchases on platforms like OpenSea will have funds manually transferred via our treasury wallet. Since these transactions are all on the Ethereum blockchain, you can watch our wallets to follow our progress and keep us honest. Four of the non-profits were selected by the founders of CryptoCandies, while the last will be chosen by the community in the ✌️community-decisions✌️ channel in <a className="primary-color" href={DISCORD_COMMUNITY_CHANNEL_INVITE_LINK} target="_blank">Discord</a>. When primary sales conclude we will begin rotating our donations to a new non-profit selected by the community each month.</p>
        </div>
        <Row bsPrefix="row candy-cards-row">
          <Col className="candy-card">
            <a href={"https://policingequity.org/"} target="_blank" className="candy-card-link">
              <Card style={{ width: '18rem' }} className="no-border">
                <Card.Img variant="top" src={policingEquityImg} />
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>Center for Policing Equity</Card.Title>
                  <Card.Body>We have the utmost respect for the Center for Policing Equity (CPE) and are very excited to be contributing to their cause of using data science to measure bias. Through our donations, we hope to amplify their critical research and move the needle on police reform.</Card.Body>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col className="candy-card">
            <a href={"https://www.brc.org/"} target="_blank" className="candy-card-link">
              <Card style={{ width: '18rem' }} className="no-border">
                <Card.Img style={{height:'80px',width:'100%'}} variant="top" src={brcImg} />
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>Bowery Residents Committee</Card.Title>
                  <Card.Body>As NYC natives we have seen first hand how Bowery Residents Committee (BRC) has been able to support people experiencing homelessness through housing and treatment services. We are honored to be able to contribute to them and aid them in providing support to NYC's homeless population.</Card.Body>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col className="candy-card">
            <a href={"https://www.hopeforpaws.org/"} target="_blank" className="candy-card-link">
              <Card style={{ width: '18rem' }} className="no-border">
                <Card.Img variant="top" src={hopeForPawsImg} />
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>Hope For Paws</Card.Title>
                  <Card.Body>Organizations like Hope For Paws are central to reducing animal abuse, neglect, and homelessness. Animals do not have a voice, but deserve to be treated humanely. Hope For Paws provides services such as rescuing animals, veterinary care, and transfers to adoption centers.</Card.Body>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col className="candy-card">
            <a href={"https://www.nokidhungry.org/"} target="_blank" className="candy-card-link">
              <Card style={{ width: '18rem' }} className="no-border">
                <Card.Img variant="top" src={noKidHungryImg} />
                <Card.Body>
                  <Card.Title style={{ textAlign: "center" }}>No Kid Hungry</Card.Title>
                  <Card.Body>We get it you can't always eat candy - but that doesn’t mean that a kid should ever go hungry. The work that NKH is doing is incredible and much needed during this pandemic. We hope our contributions can turn into some delicious meals and full bellies.</Card.Body>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col className="candy-card">
            <a href={DISCORD_COMMUNITY_CHANNEL_INVITE_LINK} target="_blank" className="candy-card-link">
              <Card style={{ width: '18rem' }} className="no-border">
                <Card.Img style={{height:'100px',width:'100px', margin:'auto' }} variant="top" src={questionMarkImg} />
                <Card.Body style={{ paddingTop: "5px"}}>
                  <Card.Title style={{ textAlign: "center" }}>TBD</Card.Title>
                  <Card.Body>The last non-profit we donate to will be decided by the CryptoCandies community. We are excited to be able to give back in a way that is meaningful to our community. Stay tuned on our Twitter and Discord for updates!</Card.Body>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
        <div className="centered">
          <p> Maybe you just aren’t hungry for CryptoCandies but want to contribute to these wonderful causes anyways - if that’s the case you can either make a deposit on <a className="primary-color" href="https://www.thegivingblock.com/" target="_blank">The Giving Block</a> directly or send ETH to our donation wallet (where we will split funds across the organisations that the community chooses). Regardless of what you do, we hope that you will share your donation in the Discord, Twitter, or private message so that we can properly thank you!</p>
        </div>
      </Container>
    </>
  )
}

export default Donations
